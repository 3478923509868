import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  
  return (
    <section className="project" id="experiences">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Experiences</h2>
                <p>Mostly worked for cloud development in IBM and Qlik</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">IBM 2012-2021</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Qlik 2021 -2023</Nav.Link>
                    </Nav.Item> 
                    <Nav.Item>
                      <Nav.Link eventKey="third">Recent Projects</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        <div>
                          <h4>Product: IBM planning analysis workspace</h4>
                          <a href="https://www.ibm.com/docs/en/planning-analytics/2.0.0"> view product</a>
                          <p>As a Visualization Team Lead for Planning Analytics Workspace, I served as the feature owner
and main contributor to the project as well as the lead UI developer. In addition, I held a parttime
role as a scrum master. My contributions included:</p>
                          <ul className="startlink">
                            <li>Implementing agile methodologies, resulting in improved project delivery time and
increased team productivity.</li>
                            <li>Collaborating with cross-functional teams to resolve technical challenges.</li>
                            <li>Enhancing system performance through the design and implementation of a generic UI
and backend framework to address generic issues.</li>
                            <li>Mentoring junior developers, providing guidance on best practices and code quality.</li>
                            <li>Acting as a full-stack developer and feature owner, consistently ranked as a top
contributor each year.</li>

                          </ul></div>
                          <div>
                            <p>Highlights:</p>
                            <ul className="startlink">
                            <li>One of original developer who built Planning analytics from scratch</li>
                            <li>Team leading experiences: successfully jump start a newly formed team consist 3 new grades and 2 senior devlopers. Get them up to speed very quickly in a complex system and acheive deliverable in 6 months efficently</li>
                            <li>Support go to person, help to resovle customer related problem in a bi-weekly basis</li>
                            </ul> 
                          </div>
                          <div className="sample">
                            <p>Some Sample Projects that I design and built</p>
                            <ul className="startlink">
                            <li><a href="https://www.youtube.com/watch?v=EGywKNH42qc"> Initial IBM planning analytics workspace</a></li>
                            <li><a href="https://www.youtube.com/watch?v=kxyPtXknbQQ"> IBM planning analytics workspace new looking</a></li>
                            <li><a href="https://www.youtube.com/watch?v=02ChRbyYQWg"> PAW predict that I’ve been leading to accomplish</a></li>
                            </ul> 
                          </div>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        <div>
                          <h4>Product: Qlik sense</h4>
                          <a href="https://www.qlik.com/us/lp/ppc/qlik-sense-business/brand?utm_team=DIG&utm_subtype=cpc_brand&ppc_id=OIja3mgN&kw=qlik%20sense&utm_content=OIja3mgN_pcrid_605410269275_pmt_e_pkw_qlik%20sense_pdv_c_mslid__pgrid_15546616745_ptaid_aud-706675959962:kwd-68438765356&utm_source=google&utm_medium=cpc&utm_campaign=Qlik_Canada_Google_Brand_DA_Brand_EN&utm_term=qlik%20sense&https://www.qlik.com/us/lp/ppc/qlik-sense-business/brand&_bt=605410269275&_bk=qlik%20sense&_bm=e&_bn=g&_bg=15546616745&gclid=CjwKCAiAxP2eBhBiEiwA5puhNTRMZlcJO3uoC0WF_FKzXP0SBoBT_QpwGVQl6_6lA0Wl6hWB2t1yMxoCjHEQAvD_BwE">view product</a>
                          <ul className="startlink">
                          <li>Led the full-stack development process, from design to architecture, resulting in the
successful creation of design documents and work flow diagrams prior to
implementation.</li>
                          <li>Developed and implemented new features in the business logic and data model for the
node.js-based data preparation backend.</li>
                          <li>Worked on multiple projects to enhance business insights and improve the user
experience through improvements in business logic and data modeling, primarily using
React and Angular.</li>

                          </ul>
                          <div className="sample">
                            <p>Some Sample Projects that I design and built</p>
                            <ul className="startlink">
                            <li><a href="https://www.youtube.com/watch?v=dCqoNSBfm2I"> NLG insights</a></li>
                            <li><a href="https://www.youtube.com/watch?v=qREfZiLZmDc"> Time Series Forecasting</a></li>
                            <li><a href="https://www.youtube.com/watch?v=GE34qF6e3_4"> Macro analysis grid-layout</a></li>
                            </ul> 
                          </div>
                          
                          </div>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        <div>
                          <h4>Taxes poker winner test - hosted on AWS with Amplify</h4>
                          <a href="https://master.d1gpp3h7uj6yz6.amplifyapp.com/">view projects</a>
                          
                          
                          </div>
                      </Row>
                    </Tab.Pane>

                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
