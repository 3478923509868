import java from "../assets/img/java.svg";
import node from "../assets/img/node.svg";
import react from "../assets/img/react.svg";
import angular from "../assets/img/angular.svg";
import mongo from "../assets/img/mongo.svg";
import redis from "../assets/img/redis.svg";
import aws from "../assets/img/aws.svg";
import ts from "../assets/img/ts.svg";
import sql from "../assets/img/sql.svg";
import olap from "../assets/img/olap.svg";
import docker from "../assets/img/docker.svg";
import git from "../assets/img/git.svg";
import jenkins from "../assets/img/jenkins.svg";
import css from "../assets/img/scss.svg";
import html from "../assets/img/html.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Tech stacks</h2>
                        <p>I have over 10 years cloud software development experiences, most recent stack with React front-end and Nodejs backend <br></br></p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            
                            <div className="item">
                                <img src={react} alt="Image" />
                                <h5>React</h5>
                            </div>
                            <div className="item">
                                <img src={angular} alt="Image" />
                                <h5>Angular</h5>
                            </div>
                            <div className="item">
                                <img src={node} alt="Image" />
                                <h5>Node Js</h5>
                            </div>
                            <div className="item">
                                <img src={java} alt="Image" />
                                <h5>java</h5>
                            </div>
                            <div className="item">
                                <img src={ts} alt="Image" />
                                <h5>Type script</h5>
                            </div>
                            <div className="item">
                                <img src={html} alt="Image" />
                                <h5>Html& JSX</h5>
                            </div>
                            <div className="item">
                                <img src={css} alt="Image" />
                                <h5>Css & Sass</h5>
                            </div>
                            <div className="item">
                                <img src={mongo} alt="Image" />
                                <h5>Mongo DB</h5>
                            </div>
                            <div className="item">
                                <img src={redis} alt="Image" />
                                <h5>Redis</h5>
                            </div>
                            <div className="item">
                                <img src={sql} alt="Image" />
                                <h5>SQL</h5>
                            </div>
                            <div className="item">
                                <img src={olap} alt="Image" />
                                <h5>OLAP</h5>
                            </div>
                            <div className="item">
                                <img src={docker} alt="Image" />
                                <h5>Docker</h5>
                            </div>
                            <div className="item">
                                <img src={git} alt="Image" />
                                <h5>Git</h5>
                            </div>
                            <div className="item">
                                <img src={jenkins} alt="Image" />
                                <h5>Jenkins</h5>
                            </div>
                            <div className="item">
                                <img src={aws} alt="Image" />
                                <h5>AWS</h5>
                            </div>
                        </Carousel>
                        <p>
                        System and architecure design<br></br>
                            Extensive knowledge and experiences of :
                        </p>
                        <ul className="listnostyle">
                           <li>multi-tenant cloud software</li>
                           <li>Micro-service architecure</li>
                           <li>Event-driven design architecure</li>
                           <li>Model view controller in OOP</li>
                           <li>Front end Responsive design</li>
                           <li>Flux architecure </li>
                           <li>Containerization with docker and k8</li> 

                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
