import { Container, Row, Col } from "react-bootstrap";
import linkedin from "../assets/img/nav-icon1.svg";
import git from "../assets/img/github.svg";
export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={6}>
          <p>@powered by React, hosted on AWS with Amplify</p>  
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
             <span className="navbar-text floatRight">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/aaron-zhang-29203349/"><img src={linkedin} alt="" /></a>
              </div>
              <div className="social-icon">
                <a href="https://github.com/aaronzz"><img src={git} alt="" /></a>
              </div>
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
